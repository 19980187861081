// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-static-page-template-js": () => import("/home/mog/code/spiral-classics/src/templates/staticPageTemplate.js" /* webpackChunkName: "component---src-templates-static-page-template-js" */),
  "component---src-pages-index-js": () => import("/home/mog/code/spiral-classics/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/home/mog/code/spiral-classics/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/home/mog/code/spiral-classics/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-original-labels-js": () => import("/home/mog/code/spiral-classics/src/pages/original-labels.js" /* webpackChunkName: "component---src-pages-original-labels-js" */),
  "component---src-pages-stock-list-js": () => import("/home/mog/code/spiral-classics/src/pages/stock-list.js" /* webpackChunkName: "component---src-pages-stock-list-js" */),
  "component---src-pages-url-generator-js": () => import("/home/mog/code/spiral-classics/src/pages/url-generator.js" /* webpackChunkName: "component---src-pages-url-generator-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/mog/code/spiral-classics/.cache/data.json")

